const Images: string[] = [
  require('./element-1.png'),
  require('./element-2.png'),
  require('./element-3.png'),
  require('./element-4.png'),
  require('./element-5.png'),
  require('./element-6.png'),
];

export default Images;
